import axios, { AxiosResponse } from "axios";
import saveAs from "file-saver";

import { ColumnSort } from "@tanstack/table-core";

interface DownloadFileParams {
  defaultFilename: string;
}

export const downloadFileFromResponse = (response: AxiosResponse, params: DownloadFileParams) => {
  let filename = params.defaultFilename;

  // Try to fetch filename from headers.
  const match: string[] = response.headers["content-disposition"]?.match(/filename="([^"]+)"/);
  if (match && match.length >= 1) {
    // The backend server uses go, which handles all strings utf8 by default. HTTP headers are, however, handled
    // as ISO-8859-1. This causes bad encoding for special characters such as accent. Decoding the string fixes
    // the issue.
    filename = decodeURIComponent(encodeURIComponent(match[1]));
  }

  // Create file link in browser's memory
  const href = URL.createObjectURL(
    new Blob([response.data] as BlobPart[], { type: response.headers["content-type"]?.toString() }),
  );

  downloadFile(href, filename);

  URL.revokeObjectURL(href);
};

export const getFile = async (url: string, filename: string): Promise<void> => {
  const res = await axios.get(url, {
    headers: {
      Accept: ["application/pdf", "image/jpeg", "image/png", "image/webp", "application/msword"],
      "Content-Type": ["application/pdf", "image/jpeg", "image/png", "image/webp", "application/msword"],
    },
    responseType: "blob",
  });

  downloadFileFromResponse(res, { defaultFilename: filename });
};

export const downloadFile = (url: string, filename: string): void => {
  saveAs(url, filename);
};

export const downloadS3File = async (path: string, filename: string) => {
  const s3URL = process.env.NEXT_PUBLIC_S3_URL;
  return await getFile(`${s3URL ?? ""}${path}`, filename);
};

export type SortingOrder = "asc" | "desc";

export const optionalTableSorting = (src: ColumnSort | undefined): SortingOrder | undefined => {
  if (src == null) {
    return undefined;
  }

  return src.desc ? "desc" : "asc";
};
