import { InterviewApi } from "@api";
import { QUERY_KEYS } from "@constants";
import { context } from "@opentelemetry/api";
import { useQueries, useQuery } from "@tanstack/react-query";
import { InterviewsConfiguration } from "@typings";
import { combineQueries } from "@utils";

export const useInterviewConfigurationByCampaignID = (campaignID?: string) =>
  useQuery({
    queryKey: [QUERY_KEYS.INTERVIEW_CONFIGURATION, campaignID],
    queryFn: () => InterviewApi.getConfiguration(context.active(), campaignID ?? ""),
    enabled: !!campaignID,
  });

export const useInterviewConfigurationsByCampaignIDs = (...campaignIDs: string[]) =>
  useQueries({
    queries: campaignIDs.map((campaignID) => ({
      queryKey: [QUERY_KEYS.INTERVIEW_CONFIGURATION, campaignID],
      queryFn: async () => ({
        campaignID,
        configuration: await InterviewApi.getConfiguration(context.active(), campaignID),
      }),
      enabled: !!campaignID,
    })),
    combine: combineQueries<
      { campaignID: string; configuration: InterviewsConfiguration | undefined },
      Record<string, InterviewsConfiguration | undefined>
    >({}, (acc, res) => {
      acc[res.campaignID] = res.configuration;
      return acc;
    }),
  });
