import { SourcingCampaignApi } from "@api";
import { QUERY_KEYS } from "@constants";
import { context } from "@opentelemetry/api";
import { useQueries, useQuery } from "@tanstack/react-query";
import { AtsIntegration } from "@typings";
import { combineQueries } from "@utils";

export const useATSIntegrationByCampaignID = (campaignID?: string) =>
  useQuery({
    queryKey: [QUERY_KEYS.ATS_INTEGRATIONS, campaignID],
    queryFn: () => SourcingCampaignApi.getAtsIntegration(context.active(), campaignID ?? ""),
    enabled: !!campaignID,
  });

export const useATSIntegrationsByCampaignIDs = (...campaignIDs: string[]) =>
  useQueries({
    queries: campaignIDs.map((campaignID) => ({
      queryKey: [QUERY_KEYS.ATS_INTEGRATIONS, campaignID],
      queryFn: async () => ({
        campaignID,
        integration: await SourcingCampaignApi.getAtsIntegration(context.active(), campaignID),
      }),
      enabled: !!campaignID,
    })),
    combine: combineQueries<{ campaignID: string; integration: AtsIntegration }, Record<string, AtsIntegration>>(
      {},
      (acc, res) => {
        acc[res.campaignID] = res.integration;
        return acc;
      },
    ),
  });
