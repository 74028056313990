import { AxiosError } from "axios";

import { ApplicationApi, SourcingCampaignApi } from "@api";
import { QUERY_KEYS } from "@constants";
import { context } from "@opentelemetry/api";
import { useQueries, useQuery } from "@tanstack/react-query";
import { ApplicationsReport, SourcingCampaign } from "@typings";
import { combineQueries, combineQueriesArrays } from "@utils";

import { useUserGroup } from "@hooks";

export const useCampaign = (campaignID?: string) =>
  useQuery<SourcingCampaign, AxiosError>({
    queryKey: [QUERY_KEYS.SOURCING_CAMPAIGNS, campaignID],
    queryFn: () => SourcingCampaignApi.get(context.active(), campaignID ?? ""),
    enabled: !!campaignID,
  });

export const useCampaignQuestionsQueries = (...campaignIDs: string[]) => {
  const userGroup = useUserGroup();

  return useQueries({
    queries: campaignIDs.map((campaignID) => ({
      queryKey: [QUERY_KEYS.CAMPAIGN_APPLICATIONS_QUESTIONS, campaignID, userGroup],
      queryFn: () =>
        ApplicationApi.listQuestions(context.active(), { campaign_id: campaignID, organization_names: [userGroup] }),
      enabled: !!campaignID && !!userGroup,
    })),
    combine: combineQueriesArrays((a, b) => a.id === b.id),
  });
};

// TODO: write a dedicated endpoint to optimize this.
export const useCampaignReportingQueries = (...campaignIDs: string[]) =>
  useQueries({
    queries: campaignIDs.map((campaignID) => ({
      queryKey: [QUERY_KEYS.APPLICATION_REPORTS, "list", campaignID],
      queryFn: async () => ({
        campaignID,
        reports: await ApplicationApi.getReporting(context.active(), { campaign_id: campaignID }),
      }),
    })),
    combine: combineQueries<{ campaignID: string; reports: ApplicationsReport }, Record<string, ApplicationsReport>>(
      {},
      (acc, res) => {
        acc[res.campaignID] = res.reports;
        return acc;
      },
    ),
  });
